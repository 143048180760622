import React from 'react'
import { Link } from "gatsby"

import Image from "../components/image"
import SEO from "../components/seo"
import { useTranslation } from "react-i18next"
import { useSelector } from 'react-redux'


const IndexPage = () => {
  const { t } = useTranslation()
  const currentRegion = useSelector(state => state.currentRegion)
  return (
    <div className="indexPage">
      <SEO title="Create my gregory" />
      <div
        className="indexPage__container"
      >
        {/*<Link
          className="indexPage__link"
          to="/story-wall">
            <span className="indexPage__bg" />
            {currentRegion}
          <Image
            filename="story_thumb.jpg"
            classname="indexPage__image mobile"
            style={{
              position: 'absolute'
            }}
          />
          <Image
            filename="story_thumb_l.jpg"
            classname="indexPage__image desktop"
            style={{
              position: 'absolute'
            }}
          /> 
        </Link> */}
        <Link
          className="indexPage__link"
          to="/product">
            <span className="indexPage__bg" />
          <Image
            filename="home__m.jpg"
            classname="indexPage__image mobile"
            style={{
              position: 'absolute'
            }}
          />
          <Image
            filename="home.jpg"
            classname="indexPage__image desktop"
            style={{
              position: 'absolute'
            }}
          />
        </Link>
      </div>
    </div>
  )
}

export default IndexPage